.dashboard-header {
    border-bottom: 1px solid #ddd;
    min-height: 50px;
    padding: 10px 0 10px 0;
    background: $light;
}

.dashboard-core {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; 
    .left-content {
        max-width: 250px;
        width: 250px;
    }

    .main-content {
        padding: 25px;
        width: 100%;
    }
}

.dashboard-core-leftmenu {
    background: $light;
    padding: 25px;
    margin-top: 25px;
    border-radius: 8px;
    border: 1px solid $border-color-dashboard;
    min-width: 225px;
    width: 225px;
    max-width: 225px;

    li {
        list-style-type: none;
    }

    li a {

        // min-height: 35px;
        // display: flex;
        // transition: all 0.3s; 
        // justify-content: flex-start;
        // align-items: center;
        // padding: 10px;
        // color: #444;
        // &:hover{
        //     background: #f8f8f8;
        // }
        min-width: 100%;

        button {
            text-align: left !important;
            color: $dark;
            font-size: 14px;
            text-transform: capitalize;
            min-width: 100% !important;
            width: 100% !important;
            justify-content: flex-start !important;
        }
    }
    svg{
        transition: ease .3s;
    }
    svg:hover{
        transform: translateY(-5px);
    }
}

.dashboard-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-item-wrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
    gap: 10px;
}

.dashboard-item {
    background: $light;
    border: 1px solid $border-color-dashboard;
    padding: 20px;
    border-radius: 8px;
    min-height: 150px;
    min-width: 200px; 
    width: 25%;
}