/**
* VARIABLES FOR THE CMS
*
* Author: Francky Rakoto
**/
//colors 
$light: #ffffff;
$gray-bg: #eeeeee;
$light-bg: #f8f8f8;
$hero-bg: #44a;
$dark: #000;
$violet: #443344;
$blue-banner: #3737ac;
$border-color: #ddd;
$red-color: #ff004499;
$red-color-hover: #ff0044dd;
$gray: #2b2b2c;
$green: #aff143;
$fav-blue: #4ad;
$red-bt: #e45e3d;
$app-default-cover: #808080;
$light-form-bg: #f4f7f8;
$grayMenu: rgba(0,0,0,0.4);
$footerParagraphColor: rgba(201, 195, 195, 0.7);
$border-color2: rgb(60,66,87);
$nice-light-bg: #F6F8FA;
$app-logo-color: #00d6ff;
$green-terminal: #00ff45;
$body-color: #f8f8f8;
$light-border-color: #f8f8f8;
$shadow-color: #0000000d;
$contact-bar-color: #e5e5e5;
$primary-color: #7a01fa;
$blue-employee: #215388;
//darkness
$darkness2: #1c2536;
//light 
$light-dashboard: #fcfcfc;

//borders 
$border-color-dashboard: rgba(224, 224, 224, 1);
//new theme background
$background2: #f521b6;
$background3: #7a01fa;
$orange1: #f52;
$orange2: #f55;
$body-main-background: linear-gradient(45deg, #f52, #7a01fa, #f55);
//mobile
$mobile: 450px;
$large: 900px;
$large2: 1022px;
$xlarge: 1200px;
$tablet: 768px;
